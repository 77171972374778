import { useState } from 'react'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { GithubIcon, LinkedinIcon, MailIcon, MapPinIcon, PhoneIcon } from 'lucide-react'
import profilePic from './portfoliopic.png'

export default function Component() {
  const [activeSection, setActiveSection] = useState('about')
  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const sections = ['about', 'skills', 'experience', 'projects']

  const toggleSection = (section: string) => {
    if (expandedSection === section) {
      setExpandedSection(null)
    } else {
      setExpandedSection(section)
      setActiveSection(section)
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-950 to-black text-white font-['Proxima Nova Black']">
      <div className="absolute inset-0 overflow-hidden">
        <svg className="absolute bottom-0 left-0 right-0 top-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2D0054" />
              <stop offset="100%" stopColor="#4A0080" />
            </linearGradient>
          </defs>
          <path d="M-500,0 C-500,0 -250,150 0,100 C250,50 500,150 750,100 C1000,50 1250,150 1500,100 C1750,50 2000,150 2250,100 C2500,50 2750,150 3000,100 L3000,1000 L-500,1000 Z" fill="url(#gradient)" fillOpacity="0.4">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="-500 0"
              dur="15s"
              repeatCount="indefinite"
            />
          </path>
          <path d="M-500,0 C-500,0 -250,150 0,100 C250,50 500,150 750,100 C1000,50 1250,150 1500,100 C1750,50 2000,150 2250,100 C2500,50 2750,150 3000,100 L3000,1000 L-500,1000 Z" fill="url(#gradient)" fillOpacity="0.4">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="-500 0"
              dur="10"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
      <div className="relative z-10">
        <header className="container mx-auto px-4 py-8">
          <nav className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Anukrit Sharma</h1>
            <div className="flex space-x-4">
              <a href="https://github.com/anukrits?tab=repositories" className="hover:text-purple-300 transition-colors">
                <GithubIcon className="w-6 h-6" />
              </a>
              <a href="https://www.linkedin.com/in/anukritsharma/" className="hover:text-purple-300 transition-colors">
                <LinkedinIcon className="w-6 h-6" />
              </a>
              <a href="mailto:anukritsharma1@gmail.com" className="hover:text-purple-300 transition-colors">
                <MailIcon className="w-6 h-6" />
              </a>
            </div>
          </nav>
        </header>
        <main className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-1">
              <div className="bg-white bg-opacity-10 rounded-lg p-6 backdrop-blur-sm">
                <img
                  alt="Profile picture"
                  className="rounded-full mx-auto mb-4"
                  height="150"
                  src={profilePic}
                  style={{
                    aspectRatio: "150/150",
                    objectFit: "cover",
                  }}
                  width="150"
                />
                <h2 className="text-xl font-semibold text-center mb-2">Anukrit Sharma</h2>
                <p className="text-center text-purple-300 mb-4">Freshman at Northeastern University</p>
                <div className="flex items-center justify-center mb-2">
                  <MapPinIcon className="w-4 h-4 mr-2" />
                  <span>Aldie, VA</span>
                </div>
                <div className="flex items-center justify-center">
                  <PhoneIcon className="w-4 h-4 mr-2" />
                  <span>+1 (540) 326-1686 or +44 7751464499</span>
                </div>
              </div>
            </div>
            <div className="md:col-span-2">
              <div className="bg-white bg-opacity-10 rounded-lg p-6 backdrop-blur-sm">
                <div className="flex flex-wrap gap-4 mb-6">
                  {sections.map((section) => (
                    <button
                      key={section}
                      className={`capitalize px-4 py-2 rounded-full transition-colors ${
                        activeSection === section
                          ? 'bg-purple-600 text-white'
                          : 'bg-white bg-opacity-10 text-gray-300 hover:bg-opacity-20'
                      }`}
                      onClick={() => toggleSection(section)}
                    >
                      {section}
                    </button>
                  ))}
                </div>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={expandedSection}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {expandedSection === 'about' && (
                      <div>
                        <h3 className="text-xl font-semibold mb-4">About Me</h3>
                        <p>
                          As a freshman at Northeastern University, I pursue my passion for cybersecurity, artificial intelligence, and business through various projects and extracurricular activities. I co-founded ParkIt!, an app that helps users find parking spots worldwide, using my skills in data analysis, web development, and user interface design. 
                          I also help teach Taekwondo classes for students of all ages at Highkicks Taekwondo and manage their online presence.
                        </p>
                      </div>
                    )}
                    {expandedSection === 'skills' && (
                      <div>
                        <h3 className="text-xl font-semibold mb-4">Skills</h3>
                        <div className="grid grid-cols-3 gap-4">
                          <div>
                            <h4 className="font-semibold mb-2">Languages</h4>
                            <ul className="list-disc list-inside">
                              <li>Python</li>
                              <li>Java</li>
                              <li>C++</li>
                              <li>HTML</li>
                              <li>CSS</li>
                              <li>JavaScript/Typescript</li>
                            </ul>
                          </div>
                          <div>
                            <h4 className="font-semibold mb-2">Frameworks</h4>
                            <ul className="list-disc list-inside">
                              <li>Tailwind CSS</li>
                              <li>React JS</li>
                            </ul>
                          </div>
                          <div>
                            <h4 className="font-semibold mb-2">Foreign Languages</h4>
                            <ul className="list-disc list-inside">
                              <li>English (Native)</li>
                              <li>German (Intermediate)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                    {expandedSection === 'experience' && (
                      <div>
                        <h3 className="text-xl font-semibold mb-4">Experience</h3>
                        <div className="mb-4">
                          <h4 className="font-semibold">Artifical Intelligence Intern at MicroHealth</h4>
                          <p className="text-sm text-purple-300">2024 - Present</p>
                          <p className="mt-2">
                            Worked with the development team to work on an artificial intelligence-based app.
                          </p>
                        </div>
                        <div className ="mb-4">
                          <h4 className="font-semibold">Taekwondo Instructor and Developer at Highkicks Taekwondo</h4>
                          <p className="text-sm text-purple-300">2022-Present</p>
                          <p className="mt-2">
                          Teach Taekwondo to over 200 students from ages 5-14, ensuring that day-to-day operations run smoothly. Currently developing a new database system to transition from paper-based to online paperwork filing. Part of the competitive Poomsae Team for Highkicks as a Second Degree Black Belt.
                          </p>
                        </div>
                        <div className="mb-4">
                          <h4 className="font-semibold">Online Grocery Pickup at Walmart</h4>
                          <p className="text-sm text-purple-300">2021 - 2022</p>
                          <p className="mt-2">
                          Worked with a staff team to move products from the shop floor into vehicles quickly and ensured that issues were quickly resolved and any problems on the floor related to the system were fixed.
                          </p>
                        </div>
                      </div>
                    )}
                    {expandedSection === 'projects' && (
                      <div>
                        <h3 className="text-xl font-semibold mb-4">Projects</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="bg-white bg-opacity-5 p-4 rounded-lg">
                            <h4 className="font-semibold">Task Manager App</h4>
                            <p className="text-sm mt-2">
                              A full-stack task management application built with React and Node.js.
                            </p>
                          </div>
                          <div className="bg-white bg-opacity-5 p-4 rounded-lg">
                            <h4 className="font-semibold">Weather Dashboard</h4>
                            <p className="text-sm mt-2">
                              A weather dashboard that displays real-time weather data using a third-party API.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </main>
        <footer className="container mx-auto px-4 py-8 text-center">
          <p>&copy; 2024 Anukrit Sharma. All rights reserved.</p>
        </footer>
      </div>
    </div>
  )
}